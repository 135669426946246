<template>
  <main class="screen justify-center items-center">
    <div class="box" v-if="suggestion">
      <h2 class="headline mb-4">{{ $t('suggest.heading') }}</h2>

      <h3 class="screen-title mb-4">
        <span class="icon-hand-o-right text-indigo-400 mr-2"></span>
        {{ suggestion.activity.title }}
        <span class="icon-hand-o-left text-indigo-400 ml-2"></span>
      </h3>

      <div class="flex xs:flex-col justify-center">
        <button v-on:click="removeActivity" class="btn xs:order-2">
          {{ $t('suggest.button_remove') }}
        </button>

        <button v-on:click="suggestNew" class="btn primary xs:mb-4 ml-3 xs:ml-0 xs:order-1">
          {{ $t('suggest.button_no') }}
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import random from '@/utils/random';
import { createId } from '@/utils/id';
import { Route } from '@/router/index';
import { SET_SUGGESTION } from '@/store/mutation-types';
import { REMOVE_ACTIVITY } from '@/store/action-types';
import { reject, propEq } from 'ramda';

const createSuggestionFromActivity = (activity) => {
  return {
    id: createId(),
    activity,
  };
};

const drawRandomActivity = (list, skipId) => random(reject(propEq('id', skipId), list));

export default {
  name: 'SuggestScreen',
  data() {
    return {
      suggestion: null,
    };
  },

  beforeMount() {
    this.suggestion = this.$store.state.suggestion;
    if (!this.suggestion) {
      this.suggestNew();
    }
  },

  methods: {
    guardSuggestionScreen() {
      // guard: at least 2 activities
      if (this.$store.state.activities.length < 2) {
        alert(this.$t('suggest.message_add_activities'));
        this.$router.push({ name: Route.ADD_ACTIVITY });
        return;
      }
    },
    suggestNew() {
      this.guardSuggestionScreen();

      const randomActivity = drawRandomActivity(
        this.$store.state.activities,
        this.suggestion ? this.suggestion.activity.id : null
      );

      this.suggestion = randomActivity ? createSuggestionFromActivity(randomActivity) : null;
      this.$store.commit(SET_SUGGESTION, this.suggestion);
    },

    removeActivity() {
      this.$store.dispatch(REMOVE_ACTIVITY, this.suggestion.activity);

      this.suggestNew();
    },
  },
};
</script>

<style scoped>
.box {
  @apply rounded-xl py-8 px-6 bg-gray-100 text-center;
  @apply w-5/6 mx-auto;
}
</style>
